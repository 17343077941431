@import "_variables";

.mini-footer{
  background: #fff;
  color: $body-font-color;
}

.decidim-logo{
  // width: 100px;
  display: inline-block;
  padding-left: 1em;
}

//Main footer
.main-footer{
  background-color: $main-footer-bg;
  color: #fff;

  a{
    color: #fff;
  }
}

.eu-footer{
  background-color: $secondary-blue;
  color: #fff;
  padding: 2em;
  font-size: .9em;

  .flag-container{
    margin-left: 1em;

    img{
      max-width: none;
    }
  }

  .disclaimer{
    float: left;
    width: 90%;

    .column{
      margin-top: 1em;
    }
  }

  @media screen and (min-width: 40em){
    display: flex;

    .flag-container{
      margin: 0 1em;
      text-align: center;
    }

    .disclaimer .column{
      margin-top: 0;
    }
  }

  .flag{
    border: 3px white solid;
    height: 80px;
    margin: 10px 0 0;
    display: inline-block;
  }
}

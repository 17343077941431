$border: 2px dashed var(--secondary);

.alternative-landing .button.button--sc {
  border-radius: 0;
}

.navbar.transparent {
  background-color: rgba(255, 255, 255, 0.7);
}

.cover-half {
  .heading1 {
    color: var(--secondary);
  }
}

.cover-full {
  background-color: unset;

  .cover-text {
    color: #484848;
    background-color: rgba(255, 255, 255, 0.7);
  }
}

.stack-horizontal {
  .heading1 {
    color: var(--primary);
  }
  .stack-item {
    border: $border;
  }

  max-width: 70em;
  margin: auto;
}

#content > section::after {
  width: 100vw;
  height: 1px;
  border-bottom: $border;
}

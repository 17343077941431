@import "variables";

.card,
.comment-thread,
.message,
.add-message,
.conference-speaker .speaker-bio,
.chart-tooltip{
  color: $body-font-color;
}
